import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
    },

    // This is root store ---------------
    state: {
        loading: false
    },
    getters: {
        isLoading: (state) => {
            return state.loading;
        }
    },
    mutations: {
        setLoading (state, value) {
            state.loading = value;
        }
    },
    actions: {
        setLoading: ({ commit }, value) => {
            commit('setLoading', value);
        }
    }
})