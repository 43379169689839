<template>
    <div class="px-7 w-100">
        <div class="alert alert-secondary text-center mt-5" v-if="tickets.length <= 0">
            <h4>ท่านยังไม่มีกิจกรรมที่ลงทะเบียนในขณะนี้</h4>
            <h4>You don't have any tickets right now.</h4>
        </div>
        <div v-else v-for="ticket in tickets" :key="ticket.id" class="row mt-5 mb-9 mx-1 position-relative" @click="showHistory(ticket.event.title, ticket)" >
            <div class="head-ticket w-100">
                <div class="col-10 mt-2 no-wrap">
                    <h4 @click="$router.push({name: 'EventDetail', params: { id: ticket.event.id }})" class="fw-bold">{{ ticket.event.title }}</h4>
                    <p class="ticket-type" style="color: #8b8b97;">{{ ticket.ticket.ticketTitle }}</p>
                </div>
                <div class="col-10">
                    {{formatTime2(ticket.event.eventStart)}} - {{formatTime2(ticket.event.eventEnd)}}
                </div>
                <div class="col-10 mt-1">
                    <font class="font-weight-bold">Quantity :</font> {{ticket.quantityRemaining}} / {{ ticket.quantity }}
                </div>
                <div class="col-10">
                    <font class="font-weight-bold">Latest Use :</font> {{formatTime2(ticket.ticket.lastAppliedAt)}} 
                </div>
                <div class="col-10">
                    <font class="font-weight-bold">by :</font> {{ ticket.ticket.lastConfirmedBy }}
                </div>
            </div>
            <div class="body-ticket w-100">
                <div class="col-10 mt-2">
                    <h6 class="text-danger">Expire in: {{ ticket.ticket.remainingTime }}</h6>
                </div>
                 <div class="col-10" style="height: 50px;">
                    <img
                        style="float: left; width: 50px; height: 40px; margin-right: 4px" 
                        src="/assets/images/1.png"/>
                    <p class="text-description text-muted m-0">
                        <font class="font-bold">Description:</font> 
                        {{ ticket.event.shortDescription ? ticket.event.shortDescription.length > 100 ?  ticket.event.shortDescription.substring(0, 100) + ' .....' : ticket.event.shortDescription : ''}} 
                    </p>
                    <p class="text-description text-warning m-0"><font class="font-bold">Note:</font> {{ ticket.event.importantNote }}</p>
                </div>
            </div>
            <div class="side-ticket position-absolute bottom-0 right-0">
                <div class="div-value text-center">
                    <h3 class="text-value text-warning">
                        ฿ 0.00
                    </h3>
                    <!-- <h5 class="text-value">
                        Value
                    </h5> -->
                </div>
                
            </div>
        </div>

        <!-- QR Modal -->
        <modal name="ticketHistory" :scrollable="false" :clickToClose="false" adaptive width="100%" height="auto">
            <div class="w-100 text-right">
                <button class="btn btn-link" @click="$modal.hide('ticketHistory')">
                    ❌
                </button>
            </div>
            <div class="text-center mx-3">
                <div class="w-100 mb-3">
                    <button @click="showQR(selectedTicket.title, selectedTicket)" class="btn btn-outline-secondary"> Show QR Code </button>
                </div>
                <div class="w-100 mb-3">
                    Quantity Remaining : {{ selectedTicket ? selectedTicket.quantityRemaining : '' }} 
                </div>
                <div class="w-100 text-left">
                    <p style="margin-bottom: -4px">Scan History</p>
                </div>
                <div class="table-responsive mb-5" style="max-height: 500px">
                <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Time</th>
                        <th scope="col">Verify By</th>
                        <th scope="col">Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(history, index) in selectedHistory" :key="index">
                            <th scope="row">{{index + 1}}</th>
                            <td>{{ formatTime(history.time) }}</td>
                            <td>{{ history.verifyBy}}</td>
                            <td>{{ history.quantity }}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </modal>
        <!-- End QR Modal -->

        <!-- QR Modal -->
        <modal name="qrModal" @opened="renderQR" adaptive width="90%" height="auto">
            <div class="text-center">
                <div class="mt-5" id="canvas">
                </div>
                <h3 class="pb-3">{{ qrTitle }}</h3>
            </div>
        </modal>
        <!-- End QR Modal -->
    </div>
</template>

<script>
import moment from 'moment';
import TicketApi from '../../apis/ticket';
import Event from '../../models/event';
import QR from 'qr-code-styling';
import { QROptions } from '../../config/qr-options';
import Ticket from '../../models/ticket';
import User from '../../models/user';
import userApi from '../../apis/user';

export default {
    data(){
        return {
            remainingTimeText: '',
            remainingTime: 3600,
            tickets: [],
            colorsHeader: [
                'red',
                'green',
                'blue',
                'AntiqueWhite',
                'Bisque',
                'BlueViolet'
            ],
            headerMapColor: [],
            qrTitle: '',
            qrImg: '',
            qrGenerater: new QR(QROptions.options2()),
            scanUrl: process.env.VUE_APP_SCAN_URL,
            user: null,
            selectedTicket: null,
            selectedHistory: []
        };
    },
    methods: {
        formatTime(date){
            return date ? moment(date).format('LT') : 'forever';
        },
        formatTime2(date){
            return date ? moment(date).format('D MMM, yyyy HH:mm') : '-';
        },
        async showHistory(title, myTicket){
            console.log(title, myTicket);
            this.selectedTicket = myTicket;
            this.$modal.show('ticketHistory');
            const response = await TicketApi.showTicketBeforeAllowAccess(this.selectedTicket.ticket.id, this.selectedTicket.id, this.selectedTicket.qrToken);
            this.selectedHistory = response.data.data.entry_logs.map(e => {
 return {
                time: new Date(e.created_at.timestamp * 1000),
                verifyBy: e.confirmed_by.username,
                quantity: e.quantity
            };
});
        },
        showQR(title, myTicket){
            console.log(myTicket);
            this.$modal.show('qrModal');
            this.qrTitle = title;
            const qrCodeUrl = `${this.scanUrl}/${myTicket.id}?ticketId=${myTicket.ticket.id}&token=${myTicket.qrToken}`;
            this.qrGenerater.update(QROptions.options2(qrCodeUrl));
        },
        renderQR(){
            this.qrGenerater.append(document.getElementById('canvas'));
        },
        reFormatLocation(value){
            if(value){
                if(value.length > 20){
                    return value.substring(0, 20) + '...';
                }
                return value;
            }else{
                return '';
            }
        }
    },
    computed: {
        headerColor(){
            return (eventId) => this.headerMapColor.find(e => e.eventId == eventId).color;
        },
        attendanceName(){
            return this.user ? this.user.firstName + ' ' + this.user.lastName : '';
        },
        attendanceEmail(){
            return this.user ? this.user.username : '';
        }
    },
    async mounted() {
        // countdown
        setInterval(() => {
            if(this.tickets.length < 0) return;
            for(const index in this.tickets){
                const remaining = (this.tickets[index].ticket.validEnd - new Date()) / 1000;
                if(remaining > 0){
                    var days = Math.floor(remaining / (60 * 60 * 24));
                    var hours = Math.floor((remaining % (60 * 60 * 24)) / (60 * 60));
                    var minutes = Math.floor((remaining % (60 * 60)) / 60);
                    var seconds = Math.floor((remaining % (60)));
                    this.tickets[index].ticket.remainingTime = `${days}d : ${hours}h : ${minutes}m : ${seconds}s`;
                }else{
                    this.tickets[index].ticket.remainingTime = '0d : 0h : 0m : 0s';
                }
            }
        }, 1000); 

        // 1. get evetns details by id
        try{
            this.$store.dispatch('setLoading', true);
            const response = await TicketApi.getMyTickets();
            let currentEventId = '';
            let index = 0;
            this.tickets = response.data.data.map(t => {
                // map color
                if(t.event.id != currentEventId){
                    currentEventId = t.event.id;
                    this.headerMapColor.push({
                        eventId: currentEventId,
                        color: this.colorsHeader[index++]
                    });
                }
                // additions parameters to inside ticket
                t.ticket.last_confirmed_by = t.last_confirmed_by;
                t.ticket.last_applied_at = t.last_applied_at;
                return {
                    id: t.id,
                    quantity: t.quantity,
                    quantityRemaining: t.quantity_remaning,
                    event: new Event(t.event),
                    qrToken: t.token,
                    ticket: new Ticket(t.ticket)
                };
            });
            const responseUserProfile = await userApi.getProfile();
            this.user = new User(responseUserProfile.data.data);
        }catch(error){
            console.log(error);
        }finally{
 this.$store.dispatch('setLoading', false);
}
    }
};
</script>

<style scoped>
    .head-ticket{
        height: 100px;
        background-color: #d6e8ff;
        line-height: 1;
    }
    .body-ticket{
        height: 100px;
        background-color: white;
    }
    .side-ticket{
        height: 100%;
        width: 20%;
        border-radius: 80% 0 0 80%;
        background-color: #333333;
    }
    .div-value{
        margin: 0;
        position: absolute;
        width: 150px;
        top: 45%;
        left: 10%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        transform: rotate(-90deg);
    }
    .text-value{
        color: white;
    }
    .text-description{
        font-size: 9px;
    }
    .font-bold{
        font-weight: bold;
    }

    @media (max-width: 575.98px) { 
        .div-value{
            left: -60%;
        }
        .side-ticket{
            width: 20%;
        }
     }
    
    @media (min-width: 575.98px) and (max-width: 1024.98px) { 
        .div-value{
            left: -10%;
        }
        .side-ticket{
            width: 20%;
        }
     }

     .ticket-type{
        line-height: 0.1;
        margin-bottom: 8px;
        margin-top: 0px;
     }
     .no-wrap{
        white-space: nowrap;
        overflow: hidden;
     }
</style>