import axios from 'axios';

/**
 * Using for call users api 
 * - login
 */
export default {
    login (credential) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const requestBody = {
            username: credential.username,
            username_type: credential.type,
            password: credential.password

        }
        return axios.post('/login', requestBody, { headers: headers });
    },
    updateProfile (json) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.patch('/profile', json, { headers: headers });
    },
    addContact (userId, json) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.patch('/profile', json, { headers: headers });
    },
    updateContact (userId, contactId, json) {
        const headers = {
            'Content-Type': 'application/json'
        };
        return axios.patch(`/users/${userId}/contacts/${contactId}`, json, { headers: headers });
    },
    getProfile () {
        return axios.get('/profile');
    },
    updateAvatar (file) {
        const formData = new FormData();
        formData.append('avatar', file);
        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        return axios.post('/avatar', formData, { headers: headers });
    },
    deleteAvatar () {
        return axios.delete('/avatar');
    },
    loginByLine (token) {
        return axios.post('/login-by-line', { access_token: token });
    }
};