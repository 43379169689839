<template>
    <div>
        <p v-if="errorMessage == ''">Authenticating ...</p>
        <p class="text-danger" v-else> {{ errorMessage }}</p>
    </div>
</template>

<script>
import liff from '@line/liff';
import userApi from '../../apis/user';

export default {
    data(){
        return {
            errorMessage: ''
        };
    },
    mounted(){
        sessionStorage.removeItem('token');
        liff.init({ liffId: process.env.VUE_APP_LIFF_ID });
        liff.ready.then(async () => {
            // 0. verify path
            const forwardTo = this.$route.query.forwardTo || null;
            // if(forwardTo == null) return;
            if(liff.isLoggedIn()){
                const lineToken = liff.getAccessToken();
                // console.log(lineToken);

                try{ // 1. get api token 
                    const response = await userApi.loginByLine(lineToken);
                    const token = response.data.data.token;
                    // const profile = await liff.getProfile();
                    
                    // 2. redirect to my ticket
                    sessionStorage.setItem('token', token);
                    if('myTicket' === forwardTo){
                        return this.$router.push({name: 'MyTicket'});
                    }else if('myEvent' === forwardTo){
                        return this.$router.push({name: 'MyEvent'});
                    }else {
                        return this.$router.push('/');
                    }
                }catch(error){
                    if(error.response && 'LGL-202' == error.response.data.code){
                        window.location.replace(process.env.VUE_APP_WC_URL);
                    }
                    this.errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message;
                }
            }else {
                liff.login();
                console.log('not login');
            }
        });

    }
}
</script>