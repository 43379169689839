import axios from 'axios';

/**
 * Using for call ticket api 
 * - get event list
 * - get event details
 */
export default {
    checkBuyTicket (ticketId) {
        return axios.get(`tickets/${ticketId}/buys/create`)
    },
    buyTicket (ticketId, tickets) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const body = {
            tickets: tickets
        };
        return axios.post(`/tickets/${ticketId}/buys`, body, { headers: headers });
    },
    getMyTickets () {
        return axios.get('/my-tickets');
    },
    showTicketBeforeAllowAccess (ticketId, myTicketId) {
        return axios.get(`/tickets/${ticketId}/buys/${myTicketId}`);
    },
};