<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Profile Personal Information-->
        <div class="d-flex flex-row">
          <SideMenu/>
          <!--begin::Content-->
          <div class="flex-row-fluid ml-lg-8">
            <!--begin::Card-->
            <div class="card card-custom card-stretch">
              <!--begin::Header-->
              <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                  <h3 class="card-label font-weight-bolder text-dark">Personal Information</h3>
                  <span class="text-muted font-weight-bold font-size-sm mt-1">Update your personal informaiton</span>
                </div>
                <div class="card-toolbar">
                  <button type="reset" class="btn btn-success">Save Changes</button>
                </div>
              </div>
              <!--end::Header-->
              <!--begin::Form-->
              <form class="form">
                <!--begin::Body-->
                <div class="card-body">
                  <div class="row">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                      <h5 class="font-weight-bold mb-6">Customer Info</h5>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Avatar</label>
                    <div class="col-lg-9 col-xl-6">
                      <div class="image-input image-input-outline" id="kt_profile_avatar" style="background-image: url(/assets/media/users/blank.png)">
                        <div class="image-input-wrapper" style="background-image: url(/assets/media/users/300_21.jpg)"></div>
                        <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                          <i class="fa fa-pen icon-sm text-muted"></i>
                          <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" />
                          <input type="hidden" name="profile_avatar_remove" />
                        </label>
                        <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
                          <i class="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                        <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="Remove avatar">
                          <i class="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                      </div>
                      <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">First Name</label>
                    <div class="col-lg-9 col-xl-6">
                      <input class="form-control form-control-lg form-control-solid" type="text" value="Nick" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Last Name</label>
                    <div class="col-lg-9 col-xl-6">
                      <input class="form-control form-control-lg form-control-solid" type="text" value="Bold" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Location</label>
                    <div class="col-lg-9 col-xl-6">
                      <input class="form-control form-control-lg form-control-solid" type="text" value="Bangkok, Thailand" />
                    </div>
                  </div>
                  <div class="row mb-4">
                    <label class="col-xl-3"></label>
                    <div class="col-lg-9 col-xl-6">
                      <h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
                      <span class="form-text text-muted">We'll never share your personal info with anyone else.</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Contact Phone</label>
                    <div class="col-lg-9 col-xl-6">
                      <div class="input-group input-group-lg input-group-solid">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-phone"></i>
                          </span>
                        </div>
                        <input type="text" class="form-control form-control-lg form-control-solid" value="+35278953712" placeholder="Phone" />
                      </div>
                    </div>
                  </div>
                  
                  <!--begin::Group-->
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Twitter</label>
                    <div class="col-lg-9 col-xl-6">
                      <div class="input-group input-group-lg input-group-solid">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-twitter"></i>
                          </span>
                        </div>
                        <input type="text" class="form-control form-control-lg form-control-solid" value="" placeholder="@event_contact" />
                      </div>
                    </div>
                  </div>
                  <!--end::Group-->
                  <!--begin::Group-->
                  <div class="form-group row">
                    <label class="col-xl-3 col-lg-3 col-form-label">Facebook</label>
                    <div class="col-lg-9 col-xl-6">
                      <div class="input-group input-group-lg input-group-solid">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="la la-facebook"></i>
                          </span>
                        </div>
                        <input type="text" class="form-control form-control-lg form-control-solid" value="" placeholder="your facebook url" />
                      </div>
                    </div>
                  </div>
                  <!--end::Group-->





                </div>
                <!--end::Body-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          <!--end::Content-->
        </div>
        <!--end::Profile Personal Information-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import SideMenu from './SideMenu'
export default {
  components: {
    SideMenu
  }
}
</script>