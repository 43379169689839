<template>
    <div class="container">
        <div class="row">
            <div v-for="(event, index) in events" :key="index" class="col-12" @click="$router.push('/event/' + event.id)">
                <!--begin::Card-->
                <div class="card card-custom mb-10">
                <img class="overlay card-img-top" 
                    :src="event.covers.length > 0 
                        ? event.covers[Math.floor(Math.random() * event.covers.length)].url 
                        : '/assets/images/noimage.png'" alt="Card image cap"/>
                <div class="card-body p-0">
                    <!--begin::Details-->
                    <div class="text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                    <router-link :to="{name:'EventDetail'}" class="font-size-h5 font-weight-bolder text-dark-75 text-hover-primary mb-1">{{ event.title }}</router-link>
                    <span class="font-size-lg">Event Start {{ timeFormat(event.eventStart) }} End  {{ timeFormat(event.eventEnd) || 'indefinitely' }}</span>
                    </div>
                    <!--end::Details-->
                </div>
                </div>
                <!--end::Card-->

            </div>
        </div>
    </div>
</template>

<script>
import eventApi from '../../apis/event';
import Event from '../../models/event';
import moment from 'moment';

export default {
    data(){
        return{
            events: []
        };
    },
	methods: {
		timeFormat(date){
		return date ? moment(date).format('D MMM, yyyy HH:mm') : null;
		}
	},
	async mounted(){
		try{
			this.events = [];
			this.$store.dispatch('setLoading', true);
			const response = await eventApi.getEventList(15, 1);
			this.events = response.data.data.map(e => new Event(e));
		}catch(error){
			console.log(error);
		}finally{this.$store.dispatch('setLoading', false)}
	}
}
</script>