<template>
	<div>
		<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#FFC0CB" />
		<HeaderMobile/>
		<div class="d-flex flex-column flex-root">
			<!--begin::Page-->
			<div class="d-flex flex-row flex-column-fluid page">
				<!--begin::Wrapper-->
				<div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
					<Topbar/>
					<!--begin::Content-->
					<div class="content d-flex flex-column flex-column-fluid" id="kt_content" style="min-height: 80vh;">
						<!--begin::Entry-->
						<div class="d-flex flex-column-fluid">
							<router-view/>
						</div>
						<!--end::Entry-->
					</div>
					<!--end::Content-->
					<Footer/>
				</div>
				<!--end::Wrapper-->
			</div>
			<!--end::Page-->
		</div>
		<!--end::Main-->
		<UserPanel/>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';

import Topbar from '@/components/Topbar';
import HeaderMobile from '@/components/HeaderMobile';
import UserPanel from '@/components/UserPanel';
// import ScrollToTop from '@/components/ScrollToTop'
import Footer from '@/components/Footer';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
	
export default {
	name: 'App',
	data() {
		return {
		};
	},
  components: {
		Topbar, 
		// HeaderMobile, UserPanel, ScrollToTop, 
		Footer,
		Loading,
		UserPanel,
		HeaderMobile
	},
	computed: {
		...mapGetters({
			isLogin: 'isLogin',
			isLoading: 'isLoading'
		})
	}

};
</script>

<style>
	@font-face {
		font-family: 'GT';
		src: url('/assets/fonts/Gotham-Bold.otf') format('opentype');
		font-weight: bold;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'GT';
		src: url('/assets/fonts/Gotham-BoldItalic.otf') format('opentype');
		font-weight: bold;
		font-style: italic;
		font-display: swap;
	}

	@font-face {
		font-family: 'GT';
		src: url('/assets/fonts/Gotham-Book.otf') format('opentype');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'GT';
		src: url('/assets/fonts/Gotham-BookItalic.otf') format('opentype');
		font-weight: normal;
		font-style: italic;
		font-display: swap;
	}

	@font-face {
		font-family: 'GT';
		src: url('/assets/fonts/Gotham-Light.otf') format('opentype');
		font-weight: 100;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'GT';
		src: url('/assets/fonts/Gotham-LightItalic.otf') format('opentype');
		font-weight: 100;
		font-style: italic;
		font-display: swap;
	}

	body {
    font-family: 'GT';
	letter-spacing: -0.5px;
	/* font-family: 'Helvethaica'; */
}
</style>

