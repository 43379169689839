<template>
  <div v-if="isGetEventDetailsErrorMessage != ''">
    Fail: {{ isGetEventDetailsErrorMessage }} : EventId : {{ $route.params.id }}
  </div>
  <div v-else class="container">
    <div class="row">
      <div class="col-12">
        <div class="card card-custom heroImage" :style="{ 'background-image': `url('${thumbnailUrl}')` }">
          
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Title -->
      <div class="col-sm-12 mb-5">
        <div class="card card-custom">
          <div class="card-header ribbon ribbon-clip ribbon-right">
            <div class="ribbon-target" style="top: 12px;">
              <span class="ribbon-inner bg-warning"></span>
              Hot!!
            </div>
            <div class="card-title">
              <h3 class="card-label">
                {{ event.title }}
              </h3>
            </div>
            <div class="card-subtitle">
            </div>
          </div>

          <!-- Time Event  -->
          <div class="card-body">
            <div class="d-flex">
              <div class="d-flex align-items-center mr-7">
                <span class="font-weight-bold mr-4">Start</span>
                  <span class="btn btn-light-primary btn-sm font-weight-bold btn-upper btn-text"> {{ `${timeFormat(event.eventStart)} ${timeFormat2(event.eventStart)}` }}</span>
                  <!-- <span class="btn btn-light-primary btn-sm font-weight-bold btn-upper btn-text"> {{ timeFormat2(event.eventStart) }}</span> -->
                
              </div>
              <div class="d-flex align-items-center">
                <span class="font-weight-bold mr-4">End</span>
                  <span class="btn btn-light-danger btn-sm font-weight-bold btn-upper btn-text"> {{ `${timeFormat(event.eventEnd)} ${timeFormat2(event.eventEnd)}` }}</span>
                  <!-- <span class="btn btn-light-danger btn-sm font-weight-bold btn-upper btn-text"> {{ timeFormat2(event.eventEnd) }}</span> -->
              </div>
            </div>
          </div>

          <!-- Number Issue -->
          <div style="display:none;">
            <div class="d-flex px-5 mb-5 align-items-cente">
              <span class="d-block font-weight-bold mr-5">Issued Ticket</span>
              <div class="d-flex flex-row-fluid align-items-center">
                <div class="progress progress-xs mt-2 mb-2 w-100">
                  <!-- <div class="progress-bar bg-success" role="progressbar" :style="`width: ${event.issued / event.limitTicket * 100}%;`" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div> -->
                </div>
                <!-- <span class="ml-3 font-weight-bolder">{{ event.issued }}/{{ event.limitTicket }}</span> -->
              </div>
            </div>
          </div>

          <!-- Location -->
          <div class="d-flex px-5 mb-5 align-items-cente">
            <span class="d-block font-weight-bold mr-5">Location: </span>
            <a :href="event.locationUrl"><i class="fas fa-map-marked"></i> {{ event.locationName }}</a>
          </div>

          <!-- price -->
          <div class="d-flex px-5 mb-5 align-items-cente">
            <span class="d-block font-weight-bold mr-5">Normal Price:</span>
            Free
          </div>
          <!-- <div class="d-flex px-5 align-items-center w-100">
            <h6 class="text-danger mx-auto"> Now Price! 'Free' </h6>
          </div> -->
          <hr/>
          <!-- Join now -->
          <div v-if="tickets.length > 0"> 
            <div class="d-flex px-5 align-items-center">
            <h5 class="d-flex"> Join Now! </h5>
            </div>
            <div class="mx-5 position-relative">
              <select style="max-width: 60%" v-model="selectedTicket">
                <option :value="null"> เลือก Ticket </option>
                <option  v-for="ticket in tickets" 
                  :key="ticket.id"  
                  :value="ticket">{{ ticket.ticketTitle }}
                </option>
              </select>
              <p>รวม ฿ 0.00</p>
              <label class="form-select font-weight-bold position-absolute top-0 right-0 mt-0 mr-3 mb-3">
                <select v-model="numberTickets" aria-placeholder="test">
                  <option :value="null">-</option>
                  <option v-for="i in maxCanBuy" :key="i" :value="i">{{ i }}</option>
                </select>
                 Tickets
              </label>
            </div>
            <hr/>
            <div class="mx-5 mb-5">
              <div v-show="errorMessage != ''" class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
              <button type="button" 
                @click="alertConfirm"
                :disabled="isPreview"
                class="btn btn-primary font-weight-bold btn-sm px-3 font-size-base">Buy Ticket</button>
            </div>
          </div>
          
        </div>
      </div>

      <!-- Price -->
      <div class="col-sm-12">
        <div class="card card-custom">
          <div class="card-header ribbon ribbon-clip ribbon-right">
            <div class="card-title">
              <h3 class="card-label">
                Description
              </h3>
            </div>
          </div>
          <div class="card-body" v-html="event.description">
          </div>
        </div>
      </div>

      <!-- Gallery  -->
      <div class="col-12 mt-5">
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Gallery
              </h3>
            </div>
          </div>
          <div class="card-body px-1" v-for="image in galleries" :key="image.id">
            <img @click="fullScreenImage(image)" :id="image.id" :src="image.url" width="100%" class="poster mb-2"/>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showImage != null" id="imageFullScreen">
      <img  v-show="showImage != null" :src="showImage" width="100%" class="imageShow"/>
    </div>
    <DialogConfirm 
      :totalPrice="0" 
      :numberQuantity="selectedTicket ? selectedTicket.quantityPerOrder : ''"
      :numberTickets="numberTickets"
      :ticketTitle="selectedTicket ? selectedTicket.ticketTitle : ''"  
      :title="event ? event.title : ''"
      @confirm="confirmBuyTicket"/>
    <DialogSuccess/>
  </div>
</template>

<script>
import DialogConfirm from '../../components/DialogConfirm';
import DialogSuccess from '../../components/DialogSuccess';
import EventApi from '../../apis/event';
import Event from '../../models/event';
import TicketApi from '../../apis/ticket';
import moment from 'moment';
import Ticket from '../../models/ticket';

export default {
  data(){
    return {
      errorMessage: '',
      isGetEventDetailsErrorMessage: '',
      event: new Event(),
      isPreview: false,
      thumbnailUrl: '',
      galleries: [],
      tickets: [],
      selectedTicket: null,
      showImage: null,
      selectedImage: null,
      numberTickets: null,
      maxCanBuy: null
    };
  },
  methods: {
    alertConfirm(){
      this.errorMessage = '';
      if(null == this.selectedTicket || null == this.numberTickets){
        this.errorMessage = 'Buy Ticket Fail: Please select ticket and number before buying';
        return;
      }
      this.$modal.show('confirm');
    },
    async confirmBuyTicket(){
      this.$store.dispatch('setLoading', true);
      this.errorMessage = '';
      try{
        await TicketApi.buyTicket(this.selectedTicket.id, this.numberTickets);
        this.$modal.hide('confirm');
        this.$store.dispatch('setLoading', false);
        this.$modal.show('success');
        await this.timeout(1700);
        this.$router.push({ name: 'MyTicket' });
      }catch(error){
        this.$modal.hide('confirm');
        this.errorMessage = error.response ? error.response.data.message : error.message;
      }finally{
this.$store.dispatch('setLoading', false);
}
      // call api
    },
    timeFormat(date){
      return date ? moment(date).format('D MMM, yyyy') : 'indefinitely';
    },
    timeFormat2(date){
      return date ? moment(date).format('H:mm') : '00:00';
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async getEventDetails() {
      this.$store.dispatch('setLoading', true);
      this.isGetEventDetailsErrorMessage = '';
      try{
        const id = this.$route.params.id;
        const response  = await EventApi.getDetails(id);
        const responseTicket = await EventApi.getEventTickets(id);
        this.event = new Event(response.data.data);
        this.thumbnailUrl = this.event.thumbnails.length > 0 ? this.event.thumbnails[0].url : '/assets/images/noimage.png';
        this.galleries = this.event.covers;
        this.tickets = responseTicket.data.data.map(t => new Ticket(t));
        this.tickets.forEach(async t => {
          const responseCheckBuyTicket = await TicketApi.checkBuyTicket(t.id);
          t.availableTicketCanBuy = responseCheckBuyTicket.data.data.available_ticket_can_buy;
        });
      }catch(error){
        console.log(error);
        this.isGetEventDetailsErrorMessage = error.response ? error.response.data.message : error.message;
      }finally{
  this.$store.dispatch('setLoading', false);
}
    },
    previewMode(){
      this.isPreview = true;
    },
    fullScreenImage(image){
      this.selectedImage = image;
      this.$fullscreen.toggle(this.$el.querySelector('#imageFullScreen'), {
        wrap: false,
        callback: this.fullScreenCallback
      });
    },
    fullScreenCallback(fullscreen){
      if(fullscreen){
        this.showImage = this.selectedImage.url;
      }else{
        this.showImage = null;
      }
    }
  },
  components: {
    DialogConfirm,
    DialogSuccess
  },
  computed: {
    totalPrice(){
      // return parseInt(this.numberTicket) * (this.ticket.price ? this.ticket.price : 0);
      return '';
    }
  },
  async mounted(){
    if('EventPreview' === this.$route.name){
      this.previewMode(); // may be using access token for access preview page
    }
    this.$store.dispatch('setLoading', true);
    setTimeout(async () => {
      await this.getEventDetails();
    }, 500);
  },
  watch: {
    async '$route.params.id'() {
      await this.getEventDetails();
    },
    selectedTicket(){
      if(this.selectedTicket != null){
        this.maxCanBuy = this.selectedTicket.availableTicketCanBuy;
        this.numberTickets = 1;
        if(0 == this.maxCanBuy){
          this.numberTickets = null;
        }
      }else{
        this.numberTickets = null;
        this.maxCanBuy = null;
      }
    }
  }
};
</script>
<style>
  .heroImage {
    background-size: cover;
    height: 300px;
    border-radius: 7px;
    margin-bottom: 20px;
  }
  .poster {
    border-radius: 7px;
  }
  .imageShow {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
</style>