export default class User {
    constructor(json) {
        if (json) {
            this.id = json.id;
            this.username = json.username;
            this.phoneNumber = json.phone_number;
            this.firstName = json.first_name;
            this.lastName = json.last_name;
            this.oganizerUrl = json.organizer_url;
            this.address = json.address;
            this.avatar = json.avatar ? json.avatar.url ? json.avatar.url.original : null : null;
            this.createdAt = json.created_at ? new Date(json.created_at.timestamp * 1000) : null;
            this.contacts = json.contacts;
        } else {
            this.id = '';
            this.username = '';
            this.phoneNumber = '';
            this.firstName = '';
            this.lastName = '';
            this.oganizerUrl = '';
            this.address = '';
            this.avatar = '';
            this.createdAt = '';
            this.contacts = '';
        }
    }
}