import axios from 'axios';

/**
 * Using for call event api 
 * - get event list
 * - get event details
 */
export default {
    getEventList (perPage, currentPage) {
        return axios.get(`/organizers/${process.env.VUE_APP_ORGANIZER}/events?per_page=${perPage}&current_page=${currentPage}`);
    },
    getDetails (id) {
        return axios.get(`events/${id}`);
    },
    getEventTickets (eventId) {
        return axios.get(`/events/${eventId}/tickets?filters=sales_period,status_available`)
    },

};