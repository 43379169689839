<template>

	<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
		<div class="d-flex flex-column-fluid">
			<!--begin::Container-->
			<div class="container">
				<!--begin::Profile Overview-->
				<div class="d-flex flex-row">
					<SideMenu/>
					<!--begin::Content-->
					<div class="flex-row-fluid ml-lg-8">
						<!--begin::Advance Table: Widget 7-->
						<div class="card card-custom gutter-b">
							<!--begin::Header-->
							<div class="card-header border-0 pt-5">
								<h3 class="card-title align-items-start flex-column">
									<span class="card-label font-weight-bolder text-dark">My Tickets</span>
									<span class="text-muted mt-3 font-weight-bold font-size-sm">More than 10+ tickets</span>
								</h3>
							</div>
							<!--end::Header-->
							<!--begin::Body-->
							<div class="card-body pt-2 pb-0 mt-n3">
								<div class="tab-content mt-5" id="myTabTables12">
																			<!--begin::Table-->
										<div class="table-responsive">
											<table class="table table-borderless table-vertical-center">
												<thead>
													<tr>
														<th class="p-0 w-50px"></th>
														<th class="p-0 min-w-200px"></th>
														<th class="p-0 min-w-120px"></th>
														<th class="p-0 min-w-120px"></th>
														<th class="p-0 min-w-120px"></th>
														<th class="p-0 min-w-50px"></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(ticket, index) in tickets" :key="index">
														<td class="p-0 py-4">
															<div class="symbol symbol-50 symbol-light mr-5">
																<span class="symbol-label">
																	<img :src="`/assets/media/svg/misc/${ticket.logo}`" class="h-50 align-self-center" alt="" />
																</span>
															</div>
														</td>
														<td class="pl-0">
															<a href="#" class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg" data-toggle="modal" data-target="#exampleModalCenter">{{ ticket.title }}</a>
															<span class="text-muted font-weight-bold d-block">{{ ticket.location}} </span>
														</td>
														<td class="text-right pr-0">
															<span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ ticket.price }}</span>
															<span class="text-muted font-weight-bold">Paid</span>
														</td>
														<td class="text-right">
															<span class="text-muted font-weight-bold">{{ ticket.usePeriod.date }}<br/>{{ ticket.usePeriod.time }}</span>
														</td>
														<td class="text-right">
															<span :class="`label label-lg label-light-${isValid(ticket.status)? 'success' : 'danger'} label-inline`">{{ ticket.status }}</span>
														</td>
														<td class="pr-0 text-right">
															<a href="#" class="btn btn-icon btn-light btn-hover-primary btn-sm" data-toggle="modal" data-target="#exampleModalCenter">
																<span class="svg-icon svg-icon-md svg-icon-primary">
																	<!--begin::Svg Icon | path:assets/media/svg/icons/General/Settings-1.svg-->
																	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
																			<rect x="0" y="0" width="24" height="24" />
																			<path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000" />
																			<path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3" />
																		</g>
																	</svg>
																	<!--end::Svg Icon-->
																</span>
															</a>
														</td>
													</tr>

												</tbody>
											</table>
										</div>
										<!--end::Table-->
								</div>
								
							</div>
							<div class="card-footer d-flex justify-content-between">
								<!--begin::Pagination-->
								<div class="d-flex justify-content-between align-items-center flex-wrap">
										<div class="d-flex flex-wrap py-2 mr-3">
												<a href="#" class="btn btn-icon btn-sm btn-light mr-2 my-1"><i class="ki ki-bold-double-arrow-back icon-xs"></i></a>
												<a href="#" class="btn btn-icon btn-sm btn-light mr-2 my-1"><i class="ki ki-bold-arrow-back icon-xs"></i></a>

												<a href="#" class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">...</a>
												<a href="#" class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">23</a>
												<a href="#" class="btn btn-icon btn-sm border-0 btn-light btn-hover-primary active mr-2 my-1">24</a>
												<a href="#" class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">25</a>
												<a href="#" class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">26</a>
												<a href="#" class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">27</a>
												<a href="#" class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">28</a>
												<a href="#" class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">...</a>

												<a href="#" class="btn btn-icon btn-sm btn-light mr-2 my-1"><i class="ki ki-bold-arrow-next icon-xs"></i></a>
												<a href="#" class="btn btn-icon btn-sm btn-light mr-2 my-1"><i class="ki ki-bold-double-arrow-next icon-xs"></i></a>
										</div>
								</div>
								<!--end:: Pagination-->
							</div>
							<!--end::Body-->
						</div>
						<!--end::Advance Table Widget 7-->
					</div>
				</div>
			</div>
			<!--end::Container-->
		</div>
		<!-- Modal-->
		<div class="modal fade" id="exampleModalCenter" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-body">
							<!--begin::Stats Widget 1-->
							<div class="card card-custom bgi-no-repeat card-border card-stretch gutter-b ribbon ribbon-top" style="background-position: right top; background-size: 30% auto; background-image: url(/assets/media/svg/shapes/abstract-4.svg)">
								<div :class="`ribbon-target bg-success`" style="top: -2px; right: 20px;">Available</div>
								<!--begin::Body-->
								<div class="card-body">
									<h3>ICON Siam New Year Party 2021</h3>
									<h6 class="text-muted">ICON Siam</h6>
									<div class="font-weight-bold text-success mt-9 mb-5">11 Jan 2021, 18:00-23:59</div>
									<p class="text-dark-75 font-weight-bolder font-size-h5 m-0">
										Remaining 1/5
									</p>
								</div>
								<!--end::Body-->
							</div>
							<!--end::Stats Widget 1-->
            </div>
						<div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
					</div>
				</div>
		</div>
	</div>
</template>

<script>
import SideMenu from './SideMenu'
export default {
	data() {
		return {
			tickets: [
				{
					logo: '005-bebo.svg',
					title: 'ICON Siam New Year Party 2021',
					location: 'ICON Siam',
					price: 'FREE',
					usePeriod: {
						date: '15 Jan 2020',
						time: '15:00-17:00'
					},
					status: 'Available'
				},
				{
					logo: '014-kickstarter.svg',
					title: 'ICON Siam New Year Party 2021',
					location: 'ICON Siam',
					price: 'FREE',
					usePeriod: {
						date: '15 Jan 2020',
						time: '15:00-17:00'
					},
					status: 'Expired'
				},
				{
					logo: '015-telegram.svg',
					title: 'ICON Siam New Year Party 2021',
					location: 'ICON Siam',
					price: 'FREE',
					usePeriod: {
						date: '15 Jan 2020',
						time: '15:00-17:00'
					},
					status: 'Available'
				},
				{
					logo: '003-puzzle.svg',
					title: 'ICON Siam New Year Party 2021',
					location: 'ICON Siam',
					price: 'FREE',
					usePeriod: {
						date: '15 Jan 2020',
						time: '15:00-17:00'
					},
					status: 'Expired'
				},
				{
					logo: '006-plurk.svg',
					title: 'ICON Siam New Year Party 2021',
					location: 'ICON Siam',
					price: 'FREE',
					usePeriod: {
						date: '15 Jan 2020',
						time: '15:00-17:00'
					},
					status: 'Available'
				}
			]
		}
	},
	methods: {
		isValid(status) {
			return status === 'Available'
		}
	},
	components: {
		SideMenu
	}
}
</script>