import LiffAuth from '@/views/auth/LiffAuth'
import Login from '@/views/auth/Login'
import EventDetail from '@/views/event/Detail'
import UserAccountInfo from '@/views/user/AccountInfo'
import UserDetail from '@/views/user/Detail'
import MyEvent from '@/views/user/MyEvent'
import MyTicket from '@/views/user/MyTicket'
import UserChangePassword from '@/views/user/Password'
import UserPersonal from '@/views/user/Personal'
import axios from 'axios'
import VueRouter from 'vue-router'


const routes = [
  {
    path: '/',
    redirect: { name: 'MyEvent' }
  },
  {
    path: '/event/:id',
    name: 'EventDetail',
    component: EventDetail
  },
  {
    path: '/event/:id/preview',
    name: 'EventPreview',
    component: EventDetail
  },
  {
    path: '/user/detail',
    name: 'UserDetail',
    component: UserDetail
  },
  {
    path: '/user/detail/personal',
    name: 'UserPersonal',
    component: UserPersonal
  },
  {
    path: '/user/detail/account',
    name: 'UserAccountInfo',
    component: UserAccountInfo
  },
  {
    path: '/user/detail/password',
    name: 'UserChangePassword',
    component: UserChangePassword
  },
  {
    path: '/user/myTicket',
    name: 'MyTicket',
    component: MyTicket
  },
  {
    path: '/user/myEvent',
    name: 'MyEvent',
    component: MyEvent
  },
  {
    path: '/liff',
    name: 'LiffAuth',
    component: LiffAuth
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to) {
    if (to.name === 'EventEdit') {
      return { x:0, y:0 }
    }
  }
})


router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('token')
  if ('LiffAuth' == to.name || 'EventPreview' == to.name)
    return next();
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    next();
  } else {
    next({ name: 'LiffAuth' });
  }
})

export default router
