<template>
    <modal name="confirm"  adaptive width="80%" height="auto">
        <div class="card card-custom">
          <div class="card-header ribbon ribbon-clip ribbon-right">
            <div class="card-title">
              <h3 class="card-label">
                <i class="fas fa-shopping-cart"></i> Confirm buy ticket
              </h3>
            </div>
          </div>
          <div class="card-body text-center">
              <div class="col-12 mb-5">
                  <H5>{{ title }}</H5>
                  <p class="font-weight-bold">{{ ticketTitle }} </p>
                  <p class="m-0"> Number Ticket: {{ numberTickets }} </p>
                  <p class="m-0"> Number Quantity:  {{numberQuantity}}</p>
                  <p> Total Price: ฿ {{ totalPrice.toFixed(2) }}</p>
              </div>
              <button type="button" @click="$emit('confirm')" class="btn btn-success col-5 mx-2">Confirm</button>
              <button type="button" @click="$modal.hide('confirm')" class="btn btn-danger col-5 mx-2">Cancel</button>
          </div>
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        numberTickets: { defualt: '' },
        numberQuantity: { defualt: '' },
        totalPrice: Number,
        title: { defualt: '' },
        ticketTitle: { default: '' }
    },
    methods: {
    }
}
</script>

<style scoped>
    .buy-icon{
        font-size: 50px;
        color: #333333;
    }
</style>